<template>
  <div id="page-top">
    <!-- Page Wrapper -->
    <div id="wrapper">
      <!-- Sidebar -->
      <Sidebar navStatus="draft" />
      <!-- End of Sidebar -->

      <!-- Content Wrapper -->
      <div id="content-wrapper" class="d-flex flex-column">
        <!-- Main Content -->
        <div id="content">
          <!-- Topbar -->
          <Header />
          <!-- End of Topbar -->

          <!-- Begin Page Content -->
          <div class="container-fluid">
            <!-- Page Heading -->
            <div
              class="d-sm-flex align-items-center justify-content-between mb-4"
            >
              <h1 class="h3 mb-0 text-gray-800">Draft Sales</h1>
            </div>

            <hr class="sidebar-divider d-none d-md-block" />

            <div class="">
              <div class="form-card card shadow mb-4">
                <div class="card-body">
                  <div class="card-header py-3">
                    <h3 class="m-0 font-weight-bold text-primary">
                      Sales Information
                    </h3>
                  </div>
                  <div class="row pt-5 pb-5">
                    <!----client and product details section--->
                    <div class="col-md-8">
                      <div class="card">
                        <div class="card-header">Client Information</div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Payment Type</label>
                                <select
                                  class="form-control"
                                  v-model="payment_type"
                                >
                                  <option value="">Select Payment Type</option>
                                  <option value="1">Full Payment</option>
                                  <option value="2">Partially Payment</option>
                                  <option value="3">Without GST</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Client Name</label>
                                <!-- <input type="text" class="form-control" v-model="cid"/> -->
                                <select class="form-control" v-model="cid">
                                  <option value="">Select Client</option>
                                  <template v-for="cdata in clientList">
                                    <option :value="cdata.id">
                                      {{ cdata.name }}
                                    </option>
                                  </template>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Billing Address</label>
                                <textarea
                                  class="form-control"
                                  v-model="billing_address"
                                  placeholder="Billing Address"
                                  rows="4"
                                ></textarea>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Delivery Address</label>
                                <textarea
                                  class="form-control"
                                  v-model="delivery_address"
                                  placeholder="Delivery Address"
                                  rows="4"
                                ></textarea>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Billing Date</label>
                                <input
                                  class="form-control"
                                  v-model="billing_date"
                                  type="date"
                                />
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Invoice No.</label>
                                <input
                                  class="form-control"
                                  v-model="invoice_no"
                                  type="number"
                                  placeholder="Invoice No."
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card mt-30">
                        <div class="card-header">Product Details</div>
                        <div class="card-body overflow-div product-details">
                          <div
                            class="row section-di"
                            v-for="(input, k) in inputs"
                            :key="k"
                          >
                            <template>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label>Select Product</label>
                                  <model-select
                                    :options="options"
                                    v-model="input.product"
                                    @input="getProductDetails(k)"
                                    placeholder="Select Product"
                                  ></model-select>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label>Thickness</label>
                                  <select
                                    class="form-control"
                                    v-model="input.thickness"
                                    @input="changePIDactThkness(k)"
                                  >
                                    <option value="">Thickness</option>
                                    <option
                                      v-for="thickness in input.thicknessList"
                                      :key="thickness.thickness"
                                      :value="thickness.thickness"
                                    >
                                      {{ thickness.thickness }}
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div class="col-md-3">
                                <div class="form-group">
                                  <label>Size</label>
                                  <select
                                    class="form-control"
                                    v-model="input.size"
                                    @change="changeTotalAmount(k)"
                                  >
                                    <option value="">Size</option>
                                    <option
                                      v-for="size in input.sizeList"
                                      :key="size.size"
                                      :value="size.size"
                                    >
                                      {{ size.size }}
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>Per Sq ft Price</label>
                                  <input
                                    type="number"
                                    v-model="input.price"
                                    @keyup="changeTotalAmount(k)"
                                    class="form-control"
                                    placeholder="Enter Per Sq ft Price"
                                  />
                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>Quantity</label>
                                  <input
                                    type="number"
                                    v-model="input.quantity"
                                    @keyup="changeTotalAmount(k)"
                                    class="form-control"
                                    placeholder="Enter Quantity"
                                  />
                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>Total</label>
                                  <input
                                    type="number"
                                    v-model="input.total"
                                    disabled
                                    class="form-control"
                                  />
                                </div>
                              </div>

                              <div class="col-md-6">
                                <span>
                                  <i
                                    class="fa fa-minus-circle"
                                    style="margin: 10px 30px; font-size: 22px"
                                    @click="removeF(k)"
                                    v-show="k || (!k && inputs.length > 1)"
                                  ></i>

                                  <i
                                    class="fa fa-plus-circle"
                                    style="margin: 10px 30px; font-size: 22px"
                                    @click="add(k)"
                                    v-show="k == inputs.length - 1"
                                  ></i>
                                </span>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!----payment right section--->
                    <div class="col-md-4">
                      <div class="card">
                        <div class="card-header">Price Details</div>
                        <div class="card-body">
                          <div class="form-group">
                            <label>Total Amount</label>
                            <input
                              class="form-control"
                              v-model="total_amount"
                              disabled
                              type="number"
                              placeholder="Total Amount"
                            />
                          </div>

                          <div class="form-group" v-if="payment_type == 2">
                            <label>Billing Amount</label>
                            <input
                              class="form-control"
                              v-model="billing_amount"
                              @keyup="changeGrandTotal()"
                              type="number"
                              placeholder="Billing Amount"
                            />
                          </div>

                          <div class="form-group" v-if="payment_type == 2">
                            <label>Non-Billing Amount</label>
                            <input
                              class="form-control"
                              v-model="non_billing_amount"
                              disabled
                              type="number"
                              placeholder="Non-Billing Amount"
                            />
                          </div>

                          <div class="form-group">
                            <label>Other Charges</label>
                            <select class="form-control" v-model="other_charge">
                              <option value="">Select Other Chargers</option>
                              <option value="Transport">
                                Transportation Charges
                              </option>
                              <option value="Unloading">
                                Unloading Charges
                              </option>
                              <option value="Transportation & Unloading">
                                Transportation & Unloading Charges
                              </option>
                            </select>
                          </div>

                          <div class="form-group" v-if="other_charge != ''">
                            <label>Pay By</label>
                            <select class="form-control" v-model="pay_by">
                              <option value="">Select Pay By</option>
                              <option value="client">Pay by Client</option>
                              <option value="ecogen">Pay by ECOGEN</option>
                            </select>
                          </div>

                          <div class="form-group" v-if="other_charge != ''">
                            <label>Other Charges Amount</label>
                            <input
                              class="form-control"
                              v-model="other_charge_amount"
                              @keyup="changeGrandTotal()"
                              type="number"
                              placeholder="Other Charges Amount"
                            />
                          </div>

                          <div class="form-group">
                            <label>Round Off</label>
                            <select class="form-control" v-model="round_off">
                              <option value="">Select Round Off</option>
                              <option value="1">Round Off Up</option>
                              <option value="2">Round Off Down/Discount</option>
                            </select>
                          </div>

                          <div class="form-group" v-if="round_off != ''">
                            <label>Round Off Amount</label>
                            <input
                              class="form-control"
                              v-model="round_off_amount"
                              @keyup="updateRoundOff"
                              type="number"
                              placeholder="Round Off Amount"
                            />
                          </div>

                          <div class="form-group">
                            <label>Grand Total</label>
                            <input
                              class="form-control"
                              v-model="grand_total"
                              disabled
                              type="number"
                              placeholder="Grand Total"
                            />
                          </div>

                          <div class="form-group">
                            <label>Paid Amount</label>
                            <input
                              class="form-control"
                              v-model="paid_amount"
                              disabled
                              type="number"
                              placeholder="Paid Amount"
                            />
                          </div>

                          <div class="form-group">
                            <label>Due Amount</label>
                            <input
                              class="form-control"
                              v-model="due_amount"
                              disabled
                              type="number"
                              placeholder="Due Amount"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="card mt-30">
                        <div class="card-header"></div>
                        <div class="card-body">
                          <div class="form-group">
                            <label>Remark</label>
                            <textarea
                              v-model="remark"
                              class="form-control"
                              rows="4"
                              placeholder="Enter Remark"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!----Payemnet details----->

                    <div class="col-md-8">
                      <button class="btn btn-info" @click="saveDraft()">
                        Save Draft
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr class="sidebar-divider d-none d-md-block" />
          </div>
          <!-- /.container-fluid -->
        </div>
        <!-- End of Main Content -->

        <!-- Footer -->
        <Footer />
        <!-- End of Footer -->
      </div>
      <!-- End of Content Wrapper -->
    </div>
    <!-- End of Page Wrapper -->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import Header from "../components/Header.vue";
import Sidebar from "../components/Sidebar.vue";
import Footer from "../components/Footer.vue";
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelSelect } from "vue-search-select";
import { parse } from "path";

export default {
  components: {
    Header,
    Sidebar,
    Footer,
    ModelSelect,
  },
  // props: ["isHas"],
  data() {
    return {
      inputs: [
        {
          pid: "",
          product: "",
          quantity: "",
          size: "",
          thickness: "",
          price: "",
          total: "",
          thicknessList: [],
          sizeList: [],
        },
      ],
      options: [],
      clientList: [],
      cid: "",
      billing_date: "",
      billing_address: "",
      delivery_address: "",
      payment_type: "",
      total_amount: 0,
      billing_amount: 0,
      other_charge: "",
      other_charge_amount: 0,
      paid_amount: 0,
      due_amount: 0,
      grand_total: 0,
      non_billing_amount: 0,
      amount: 0,
      paymentDate: "",
      invoice_no: "",
      payment_mode: "",
      remark: "",
      user_token: localStorage.getItem("user_token"),
      user_token_name: localStorage.getItem("user_token_name"),
      hideGST: true,
      sizeList: [],
      thicknessList: [],
      round_off: "",
      round_off_amount: 0,
      pay_by: "",

      checks: JSON.parse(localStorage.getItem("checks")),
      role: localStorage.getItem("role"),
    };
  },
  mounted() {
    if (
      localStorage.getItem("user_token") == "" ||
      !localStorage.getItem("user_token") > 0
    ) {
      this.$router.push("/login");
    }

    if (this.role != "Admin") {
      if (this.checks.sales == 1 || this.check.draft_sales == 1) {
      } else {
        this.$router.push("/login");
      }
    }
    this.getProductList();
    this.getClientList();
    // this.getProductDetails();
  },
  watch: {
    cid: function (vl) {
      this.cid = vl;
      this.getClientAddress();
    },
    billing_amount: function (vl) {
      this.chnageNonBilling();
    },
    total_amount: function (vl) {
      if (this.payment_type == 2) {
        this.chnageNonBilling();
      }
    },
    other_charge_amount: function (vl) {
      if (this.payment_type == 1) {
        if (vl > 0) {
          this.grand_total = parseInt(this.total_amount) + parseInt(vl);
        } else {
          this.grand_total = parseInt(this.total_amount) + 0;
        }
      } else {
        if (vl > 0) {
          this.grand_total = parseInt(this.billing_amount) + parseInt(vl);
        } else {
          this.grand_total = parseInt(this.billing_amount) + 0;
        }
      }
    },
  },
  methods: {
    changePIDactThkness(index) {
      setTimeout(() => {
        const item = this.inputs[index];
        let choosenThickness = item.thicknessList.find(
          (e) => e.thickness == item.thickness
        );
        this.inputs[index].pid = choosenThickness.id;
        let size_data = '<option value="">Size</option>';
        item.sizeList
          .filter((e) => e.id == choosenThickness.id)
          .forEach((value, index) => {
            size_data +=
              "<option value=" + value.size + ">" + value.size + "</option>";
          });
        $(".size-" + index).html(size_data);
      }, 10);
      return;
    },
    add(index) {
      this.inputs.push({
        pid: "",
        quantity: 0,
        size: "",
        thickness: "",
        price: 0,
        total: 0,
      });
      console.log($(".product-details").height());
      var d = $(".product-details");
      d.scrollTop(d.prop("scrollHeight"));
    },
    getClientAddress() {
      var ev = this;
      axios({
        method: "POST",
        url: this.$root.URL_ROOT + "inventory_api.php",
        data: {
          type: "getClientAdress",
          cid: this.cid,
        },
      }).then(function (response) {
        var data = response.data;
        if (data.status == "success") {
          ev.billing_address = data.address;
          // console.log(data)
        }
      });
    },
    removeF(index) {
      this.inputs.splice(index, 1);
      this.changeTotalAmount();
    },
    getProductList() {
      var ev = this;
      axios({
        method: "POST",
        url: this.$root.URL_ROOT + "inventory_api.php",
        data: {
          type: "getProductListDropDown",
        },
      }).then(function (response) {
        var data = response.data;
        if (data.status == "success") {
          let filtered = [];
          data.data.forEach((e) => {
            const item = {
              value: e.text,
              text: e.text,
            };
            if (!filtered.find((e) => e.text == item.text)) {
              filtered.push(item);
            }
          });
          ev.options = filtered;
        }
      });
    },
    getClientList() {
      var ev = this;
      axios({
        method: "POST",
        url: this.$root.URL_ROOT + "inventory_api.php",
        data: {
          type: "getClient",
        },
      }).then(function (response) {
        var data = response.data;
        console.log(data);
        if (data.status == "success") {
          ev.clientList = data.data;
        }
      });
    },
    saveDraft() {
      const ev = this;

      if (this.cid == "" || this.cid == null) {
        this.$toasted.global.error({ message: "please select client" });
      } else {
        axios({
          method: "POST",
          url: this.$root.URL_ROOT + "inventory_api.php",
          data: {
            type: "saveDraft",
            cid: this.cid,
            billing_date: this.billing_date,
            payment_type: this.payment_type,
            billing_address: this.billing_address,
            invoice_no: this.invoice_no,
            delivery_address: this.delivery_address,
            total_amount: this.total_amount,
            billing_amount: this.billing_amount,
            non_billing_amount: this.non_billing_amount,
            paid_amount: this.paid_amount,
            other_charge: this.other_charge,
            other_charge_amount: this.other_charge_amount,
            due_amount: this.due_amount,
            product_details: this.inputs,
            grand_total: this.grand_total,
            amount: this.amount,
            payment_date: this.paymentDate,
            payment_mode: this.payment_mode,
            remark: this.remark,
            round_off: this.round_off,
            round_off_amount: this.round_off_amount,
            pay_by: this.pay_by,
          },
        }).then(function (response) {
          var data = response.data;
          console.log(data);
          if (data.status == "success") {
            ev.$toasted.global.success({ message: data.msg });
            ev.$router.push("/draft-sales");
          } else {
            ev.$toasted.global.error({ message: data.msg });
          }
        });
      }
    },
    chnageNonBilling() {
      this.non_billing_amount = this.total_amount - this.billing_amount;
    },
    changeTotalAmount(ids) {
      let total = 0;
      const ev = this;
      if (!(ids == undefined)) {
        const splitData = this.inputs[ids].size.split("X");
        const area = splitData[0] * splitData[1];
        this.inputs[ids].total =
          this.inputs[ids].price * area * this.inputs[ids].quantity;
      }

      this.inputs.forEach((value, index) => {
        total = total + value.total;
        // console.log(total)
        ev.total_amount = total;
      });
      if (this.payment_type == 1) {
        ev.grand_total =
          parseInt(ev.total_amount) + parseInt(ev.other_charge_amount);
      }
    },
    changeGrandTotal() {
      if (this.pay_by == "client") {
        this.grand_total =
          parseInt(this.total_amount) + parseInt(this.other_charge_amount);
      } else {
        this.grand_total = parseInt(this.total_amount);
      }
    },
    changeDueAmount() {
      if (this.payment_mode == 2) {
        this.non_billing_due_amount =
          parseInt(this.grand_total) - parseInt(this.amount);
      } else {
        this.due_amount = parseInt(this.grand_total) - parseInt(this.amount);
      }
    },
    changePaidAmount() {
      this.paid_amount = this.amount;
    },
    updateRoundOff() {
      if (this.round_off_amount > 0) {
        if (this.round_off == "1") {
          if (this.pay_by == "client") {
            this.grand_total =
              parseInt(this.total_amount) +
              parseInt(this.round_off_amount) +
              parseInt(this.other_charge_amount);
          } else {
            this.grand_total =
              parseInt(this.total_amount) + parseInt(this.round_off_amount);
          }
        } else if (this.round_off == "2") {
          // console.log('minus',parseInt(this.total),parseInt(this.round_off_amount),parseInt(this.other_charge_amount))
          if (this.pay_by == "client") {
            this.grand_total =
              parseInt(this.total_amount) +
              parseInt(this.other_charge_amount) -
              parseInt(this.round_off_amount);
          } else {
            his.grand_total =
              parseInt(this.total_amount) - parseInt(this.round_off_amount);
          }
        }
      } else {
        if (this.pay_by == "client") {
          this.grand_total =
            parseInt(this.total_amount) + parseInt(this.other_charge_amount);
        } else {
          this.grand_total = parseInt(this.total_amount);
        }
      }
    },
    getProductDetails(ids) {
      const ev = this;
      // console.log(ids)

      axios({
        method: "POST",
        url: this.$root.URL_ROOT + "inventory_api.php",
        data: {
          type: "getProductSize",
          name: this.inputs[ids].product,
        },
      }).then(function (response) {
        const data = response.data;
        // console.log(data)
        let thickness_data = '<option value="">Thickness</option>';
        let size_data = '<option value="">Size</option>';
        data.thickness.forEach((value, index) => {
          // console.log(value)
          // $(".thickness-"+ids).append("<option value="+value.thickness+">"+value.thickness+"</option>");
          thickness_data +=
            '<option value="' +
            value.thickness +
            '">' +
            value.thickness +
            "</option>";
        });
        $(".thickness-" + ids).html(thickness_data);

        data.size.forEach((value, index) => {
          // console.log(value)
          // $(".thickness-"+ids).append("<option value="+value.thickness+">"+value.thickness+"</option>");
          size_data +=
            '<option value="' + value.size + '">' + value.size + "</option>";
        });

        $(".size-" + ids).html(size_data);

        ev.inputs[ids].sizeList = data.size;
        ev.inputs[ids].thicknessList = data.thickness;
      });
    },
  },
};
</script>
